import axios from 'axios'
import service from './index'
import qs from 'qs'
import { getToken, removeToken } from '@/utils/cookies'
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/message.css'
import VueRouter from '@/router'


/**
 * post请求
 * url 请求地址
 * postData 请求参数
 * configs 参数说明
 * notCheckDouble Boolean 不需要校验重复接口
 * notNeedToken Boolean 不需要token
 * isNQS Boolean 不需要qs处理参数
 * jsonType Boolean 请求为jsonType 需要配置请求头
 */
 let pending = [] // 声明一个数组用于存储每个ajax请求
 export const requestPost = (url, postData = {}, configs = {}) => {
    if (!configs.notCheckDouble) {
      // 前面一个同样的请求未返回结果，连续发出的请求均取消
      if (~pending.indexOf(url)) {
        return new Promise(() => {})
      }
    }
    pending.push(url)
    if (!configs.notNeedToken) {
      if (!getToken()) {
        Message.error('登录信息已失效,请重新登录!')
        removeToken()
        VueRouter.push('/login')
        return
      }
    }
    if (!configs.isNQS) {
      postData = qs.stringify(postData)
    }
    if (configs.jsonType) {
      configs.headers = {
        'Content-Type':'application/x-www-form-urlencoded;charset=UTF-8', //配置请求头

      }
    }
    return service.post(url, postData, configs).then((res) => {
      if (~pending.indexOf(url)) {
        pending.splice(pending.indexOf(url), 1)
      }
      if (res.data.code === 401) {
        removeToken()
        Message.error(res.data.message)
        VueRouter.push('/login')
      }
      // 设置相应头参数
      if(configs.isHeader) {
        res.data.headers = res.headers
      }
      return res.data
    }, (res) => {
      if (~pending.indexOf(url)) {
        pending.splice(pending.indexOf(url), 1)
      }
      if (axios.isCancel(res)) {
        //   
      }
      return res
    })
  }

/**
 * get请求
 * params 请求参数
 * configs 参数说明
 * notCheckDouble Boolean 不需要校验重复接口
 * notNeedToken Boolean 不需要token
 */

 export const requestGet = (url, params = {}, configs = {}) => {
    if (!configs.notCheckDouble) {
      // 前面一个同样的请求未返回结果，连续发出的请求均取消
      if (~pending.indexOf(url)) {
        return new Promise(() => {})
      }
    }
    pending.push(url)
    if (!configs.notNeedToken) {
      if (!getToken()) {
        removeToken()
        Message.error('登录信息已失效,请重新登录!')
        VueRouter.push('/login')
        return
      }
    }

    // 路径参数 eg:/mqtt/device-model/exit-edit/{modelId}
    if(Object.prototype.toString.call(params) !== "[object Object]") {
      url = `${url}/${params}`
      params = {}
    }
    return service.get(url, { params, ...configs }).then((res) => {
      if (~pending.indexOf(url)) {
        pending.splice(pending.indexOf(url), 1)
      }
      if (res.data.code === 401) {
        removeToken()
        Message.error(res.data.message)
        VueRouter.push('/login')
      }
      // 设置相应头参数
      if(configs.isHeader) {
        res.data.headers = res.headers
      }
      // 可以根据相关接口对此处作拦截处理
      // if (res.data.result !== 1 || res.data.code === 0) {
      //   Message.error(res.data.msg || res.msg || '网络不给力')
      //   return new Promise(() => {})
      // }
      return res.data
    }, (res) => {
      if (~pending.indexOf(url)) {
        pending.splice(pending.indexOf(url), 1)
      }
      if (axios.isCancel(res)) {
        // console.log('Request canceled', res.msg)
      }
      return res
    })
  }
