import axios from 'axios';
import { getToken } from '@/utils/cookies'
axios.defaults.withCredentials = true

// 创建axios实例..
const service = axios.create({
    // baseURL: 'https://live.test.rootcloudapp.com',
    timeout: 180 * 1000,
    headers: {
        'Content-Type':'application/json;charset=UTF-8'
    }
})


// axios的拦截器配置
service.interceptors.request.use(config => {
    const method = config.method
    const userId = sessionStorage.getItem('userId') || ''
    if(userId) {
        config.headers['X-User-Id'] = userId
    }
    config.headers['Authorization'] = getToken()

    if(method === 'post') {
        config.headers = {
            ...config.headers,
            'Accept': 'application/json, text/plain, */*',
            'Accept-Language': 'zh-CN,zh;q=0.9'
        }
    } else if (method === 'get') {
        config.headers = {
            ...config.headers,
        }
    }
    if(config.sessionkeyid) {
        config.headers.sessionKeyId = config.sessionkeyid
    }
    return config
}, (error) => {
    return error
})

export default service